<template>
  <div class="student-manage-edit">
    <div class="student-manage-edit-title">
      <div class="text">
        <span>{{ pageTitle }}</span>
        <div class="line"></div>
      </div>
    </div>
    <div class="first-panel" style="margin-top: 2.65625vw">
      <div class="panel-header">
        <div class="title">基础信息</div>
        <div class="button">
          <img
            :src="firstPanelTurnIcon"
            alt=""
            @click="handleItem('base')"
            :class="{ 'turn-icon-class': !showBaseFlag }"
          />
        </div>
      </div>
      <div class="panel-content" v-show="showBaseFlag">
        <el-form
          class="content-form"
          :model="studentBaseForm"
          ref="studentBaseForm"
          :rules="baseRules"
          label-width="6.25vw"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="头像：" class="form-choose-item">
                <el-upload
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :headers="uploadHeader"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :accept="'.png,.jpg'"
                >
                  <img
                    v-if="studentBaseForm.coverImg"
                    :src="studentBaseForm.coverImg"
                    class="avatar"
                  />
                  <i
                    v-if="studentBaseForm.coverImg"
                    class="el-icon-error delete-icon"
                    @click.stop="delStudentCoverImg"
                  ></i>
                  <div v-else class="avatar-uploader-info">
                    <img class="avatar-uploader-icon" :src="uploadImgIcon" />
                    <div class="avatar-uploader-title">上传图片</div>
                    <div class="avatar-uploader-tip">
                      图片尺寸100 * 100，仅限上传1张
                    </div>
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item label="照片：" class="form-choose-item">
                <el-upload
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :headers="uploadHeader"
                  :show-file-list="false"
                  :on-success="handlePhotoSuccess"
                  :before-upload="beforePhotoUpload"
                  :accept="'.png,.jpg'"
                >
                  <img
                    v-if="studentBaseForm.photoImg"
                    :src="studentBaseForm.photoImg"
                    class="avatar"
                  />
                  <i
                    v-if="studentBaseForm.photoImg"
                    class="el-icon-error delete-icon"
                    @click.stop="delStudentPhotoImg"
                  ></i>
                  <div v-else class="avatar-uploader-info">
                    <img class="avatar-uploader-icon" :src="uploadImgIcon" />
                    <div class="avatar-uploader-title">上传图片</div>
                    <div class="avatar-uploader-tip">
                      图片尺寸40 * 40，仅限上传1张
                    </div>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="form-choose-item"
                label="学生姓名："
                prop="studentName"
              >
                <el-input
                  class="form-input"
                  v-model="studentBaseForm.studentName"
                  placeholder="请输入学生姓名，支持汉字、数字、字母组合"
                  :maxlength="50"
                ></el-input>
              </el-form-item>
              <el-form-item class="form-choose-item" label="昵称：">
                <el-input
                  class="form-input"
                  v-model="studentBaseForm.nickName"
                  placeholder="请输入昵称"
                  :maxlength="50"
                ></el-input>
              </el-form-item>
              <el-form-item class="form-choose-item" label="家庭住址：">
                <el-input
                  class="form-input"
                  v-model="studentBaseForm.address"
                  placeholder="请输入家庭住址"
                  :maxlength="50"
                ></el-input>
              </el-form-item>
              <el-form-item class="form-choose-item" label="性别：">
                <el-radio-group v-model="studentBaseForm.sex">
                  <el-radio :label="'1'">男</el-radio>
                  <el-radio :label="'2'">女</el-radio>
                  <el-radio :label="'3'">保密</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="form-choose-item" label="学生编号：">
                <el-input
                  class="form-input"
                  v-model="studentBaseForm.studentNum"
                  placeholder="请输入学生编号"
                  :maxlength="50"
                ></el-input>
              </el-form-item>
              <el-form-item class="form-choose-item" label="学籍号：">
                <el-input
                  class="form-input"
                  v-model="studentBaseForm.studentStudyNum"
                  placeholder="请输入学籍号"
                  :maxlength="50"
                ></el-input>
              </el-form-item>
              <el-form-item class="form-choose-item" label="身份证号：">
                <el-input
                  class="form-input"
                  v-model="studentBaseForm.personID"
                  placeholder="请输入身份证号"
                  :maxlength="50"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="first-panel">
      <div class="panel-header">
        <div class="title">入园信息</div>
        <div class="button">
          <img
            :src="firstPanelTurnIcon"
            alt=""
            @click="handleItem('enter')"
            :class="{ 'turn-icon-class': !showEnterFlag }"
          />
        </div>
      </div>
      <div class="panel-content" v-show="showEnterFlag">
        <el-form
          class="content-form"
          :model="studentEnterForm"
          ref="studentEnterForm"
          :rules="enterRules"
          label-width="6.25vw"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="学校："
                class="form-choose-item"
                :prop="type == 'class' ? 'schoolName' : ''"
              >
                <el-select
                  class="form-input"
                  v-model="studentEnterForm.schoolName"
                  clearable
                  placeholder="请选择学校"
                >
                  <el-option
                    v-for="item in schoolOptions"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="入园日期：" class="form-choose-item">
                <el-date-picker
                  v-model="studentEnterForm.enterDate"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="离校日期：" class="form-choose-item">
                <el-date-picker
                  v-model="studentEnterForm.leaveDate"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="年级："
                class="form-choose-item"
                :prop="type == 'class' ? 'gradeName' : ''"
              >
                <el-select
                  class="form-input"
                  v-model="studentEnterForm.gradeName"
                  clearable
                  placeholder="请选择年级"
                >
                  <el-option
                    v-for="item in gradeOptions"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="form-choose-item" label="英文名：">
                <el-input
                  class="form-input"
                  v-model="studentBaseForm.englishName"
                  placeholder="请输入英文名"
                  :maxlength="50"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="在校状态："
                class="form-choose-item"
                :prop="type == 'class' ? 'enterStatus' : ''"
              >
                <el-select
                  class="form-input"
                  v-model="studentEnterForm.enterStatus"
                  clearable
                  placeholder="请选择在校状态"
                >
                  <el-option
                    v-for="item in enterStatusOptions"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="班级："
                class="form-choose-item"
                :prop="type == 'class' ? 'className' : ''"
              >
                <el-select
                  class="form-input"
                  v-model="studentEnterForm.className"
                  clearable
                  placeholder="请选择班级"
                >
                  <el-option
                    v-for="item in classOptions"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="form-choose-item" label="学届：">
                <el-date-picker
                  v-model="studentEnterForm.year"
                  type="year"
                  placeholder="选择年份"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="学生状态：" class="form-choose-item">
                <el-select
                  class="form-input"
                  v-model="studentEnterForm.status"
                  clearable
                  placeholder="请选择学生状态"
                >
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="first-panel">
      <div class="panel-header">
        <div class="title">家长信息</div>
        <div class="button">
          <img
            :src="firstPanelTurnIcon"
            alt=""
            @click="handleItem('parent')"
            :class="{ 'turn-icon-class': !showParentFlag }"
          />
        </div>
      </div>
      <div class="panel-content" v-show="showParentFlag">
        <el-form onsubmit="return false" :inline="true">
          <el-row>
            <el-col :span="24">
              <el-form-item style="float: right">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  @click="handleAddParent"
                  >添加</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="table-content">
          <el-table
            ref="parentsTable"
            :data="tableData"
            v-loading="tableLoading"
            style="width: 100%"
          >
            <el-table-column
              prop="parentName"
              label="家长姓名"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="parentPhone"
              label="手机号码"
              width="200"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="relationship"
              label="关系"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="firstPerson"
              label="第一联系人"
              align="center"
            >
              <template slot-scope="scope">
                <el-switch v-model="scope.row.firstPerson"></el-switch>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <span class="table-del" @click="handleDel(scope.row)"
                  >删除
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="edit-footer">
      <el-button :loading="buttonloading" @click="resetStudent"
        >重 置</el-button
      >
      <el-button :loading="buttonloading" type="primary" @click="submitStudent"
        >提交</el-button
      >
    </div>
    <el-dialog
      title="添加家长"
      :visible.sync="parentDialogVisible"
      :append-to-body="true"
      :destroy-on-close="true"
      @before-close="handleClose"
      @close="handleClose"
      width="50%"
    >
      <el-form
        class="dialog-form"
        :model="parentDialogForm"
        ref="parentDialogForm"
        :rules="parentDialogRules"
        label-width="6.25vw"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="选择关系："
              prop="relationship"
            >
              <el-select
                class="form-input"
                v-model="parentDialogForm.relationship"
                clearable
                placeholder="请选择关系"
              >
                <el-option
                  v-for="item in relationshipOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="家长姓名："
              prop="parentName"
            >
              <el-input
                class="form-input"
                v-model="parentDialogForm.parentName"
                placeholder="请输入家长姓名"
                :maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="手机号码："
              prop="parentPhone"
            >
              <el-input
                class="form-input"
                v-model="parentDialogForm.parentPhone"
                placeholder="请输入手机号码"
                :maxlength="11"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="证件类型：">
              <el-select
                class="form-input"
                v-model="parentDialogForm.cardType"
                clearable
                placeholder="请选择证件类型"
              >
                <el-option
                  v-for="item in cardTypeOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="证件号码："
              prop="parentPhone"
            >
              <el-input
                class="form-input"
                v-model="parentDialogForm.cardNum"
                placeholder="请输入证件号码"
                :maxlength="11"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="详细地址：">
              <el-input
                class="form-input"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="请填写详细地址"
                v-model="parentDialogForm.address"
                resize="none"
                :maxlength="100"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonloading" @click="handleClose"
          >取 消</el-button
        >
        <el-button :loading="buttonloading" type="primary" @click="handleSubmit"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import firstPanelTurnIcon from "@/assets/img/first-panel-turn-icon.png";
import uploadImgIcon from "@/assets/img/upload-img-icon.png";
import LTable from "@/components/tableComponent/index.vue";
import { getToken } from "@/utils/auth";
import { noSymbol } from "@/utils/validate.js";
export default {
  name: "studentManageEdit",
  components: {
    LTable,
  },
  data() {
    let checkName = (rule, value, callback) => {
      if (value === "") {
        return callback();
      }
      if (value !== "" && !noSymbol(value)) {
        callback(new Error("只支持汉字、数字、字母组合"));
      } else {
        callback();
      }
    };
    return {
      pageTitle: "添加学生", //详情title
      //基本信息显隐标识
      showBaseFlag: true,
      //入园信息显隐标识
      showEnterFlag: true,
      //家长信息显隐标识
      showParentFlag: true,
      firstPanelTurnIcon, //旋转按钮
      uploadImgIcon, //上传按钮

      uploadUrl:
        (process.env.NODE_ENV == "development"
          ? process.env.VUE_APP_LOCAL_URL + "/webForm"
          : process.env.VUE_APP_LOCAL_URL) + "/api/upload/uploadImgToOSS", //上传地址
      uploadHeader: { token: this.getToken() },
      fileTypeList: ["png", "jpg"], //文件类型list

      studentBaseForm: {
        coverImg: "",
        photoImg: "",
        studentName: "",
        nickName: "",
        address: "",
        sex: "1",
        studentNum: "",
        studentStudyNum: "",
        personID: "",
      }, //基本信息
      baseRules: {
        studentName: [
          {
            required: true,
            message: "请输入学生姓名，支持汉字、数字、字母组合",
            trigger: "blur",
          },
          { min: 2, message: "学生姓名长度不能小于2", trigger: "blur" },
          { validator: checkName, trigger: "blur" },
        ],
      }, //基本信息规则

      studentEnterForm: {
        schoolName: "",
        enterDate: "",
        leaveDate: "",
        gradeName: "",
        englishName: "",
        enterStatus: "",
        className: "",
        year: "",
        status: "",
      }, //入园信息
      enterRules: {
        schoolName: [
          { required: true, message: "请选择学校", trigger: "change" },
        ],
        gradeName: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
        className: [
          { required: true, message: "请选择班级", trigger: "change" },
        ],
        enterStatus: [
          { required: true, message: "请选择在校状态", trigger: "change" },
        ],
      }, //入园信息规则

      columns: [
        {
          label: "家长姓名",
          slotName: "parentName",
        },
        {
          label: "手机号码",
          slotName: "parentPhone",
        },
        {
          label: "关系",
          slotName: "relationship",
        },
        {
          label: "年级",
          slotName: "firstPerson",
        },
        {
          label: "班级",
          slotName: "studentClass",
        },
        {
          label: "状态",
          slotName: "status",
        },
        {
          label: "第一联系人",
          slotName: "firstPerson",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ], //家长信息列表
      tableData: [], //列表数据
      tableLoading: false, //列表loading

      parentDialogVisible: false, //添加家长弹窗显隐标识
      parentDialogForm: {
        relationship: "",
        parentName: "",
        parentPhone: "",
        cardType: "",
        cardNum: "",
        address: "",
      }, //添加家长表单
      parentDialogRules: {
        relationship: [
          { required: true, message: "请选择关系", trigger: "change" },
        ],
        parentName: [
          { required: true, message: "请输入家长名称", trigger: "blur" },
        ],
        parentPhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
      }, //添加家长表单规则
      buttonloading: false, //按钮loading

      gradeOptions: [], //年级下拉
      classOptions: [], //班级下拉
      enterStatusOptions: [], //在校状态下拉
      statusOptions: [], //学生状态下拉
      schoolOptions: [], //学校下拉
      relationshipOptions: [], //关系下拉
      cardTypeOptions: [], //证件类型下拉

      type: "", //进入添加学生页面的类型
    };
  },
  methods: {
    getToken,
    //操作事件
    handleItem(type) {
      switch (type) {
        case "base":
          this.showBaseFlag = !this.showBaseFlag;
          break;
        case "enter":
          this.showEnterFlag = !this.showEnterFlag;
          break;
        case "parent":
          this.showParentFlag = !this.showParentFlag;
          break;
      }
    },
    //上传头像成功事件
    handleAvatarSuccess(res, file) {
      console.log(res);
      this.studentBaseForm.coverImg = res;
    },
    //上传头像之前事件
    beforeAvatarUpload(file) {
      const isJPG = this.fileTypeList.indexOf(file.name.substr(file.name.lastIndexOf(".")+1,file.name.length)) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 png, jpg 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M; //&& isLt2M
    },
    //删除头像
    delStudentCoverImg() {
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h(
            "span",
            {
              style:
                "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
            },
            "确定要删除该头像？"
          ),
          h(
            "div",
            {
              style:
                "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
            },
            "是否确定删除该头像，删除后，将无法恢复。"
          ),
        ]),
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          iconClass: "el-icon-question",
          type: "warning",
          customClass: "warningMessage",
        }
      )
        .then(() => {
          this.studentBaseForm.coverImg = "";
        })
        .catch(() => {});
    },
    //上传照片成功事件
    handlePhotoSuccess(res, file) {
      console.log(res);
      this.studentBaseForm.photoImg = res;
    },
    //上传照片之前事件
    beforePhotoUpload(file) {
      const isJPG = this.fileTypeList.indexOf(file.name.substr(file.name.lastIndexOf(".")+1,file.name.length)) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 png, jpg 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M; //&& isLt2M
    },
    //删除照片
    delStudentPhotoImg() {
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h(
            "span",
            {
              style:
                "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
            },
            "确定要删除该照片？"
          ),
          h(
            "div",
            {
              style:
                "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
            },
            "是否确定删除该照片，删除后，将无法恢复。"
          ),
        ]),
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          iconClass: "el-icon-question",
          type: "warning",
          customClass: "warningMessage",
        }
      )
        .then(() => {
          this.studentBaseForm.photoImg = "";
        })
        .catch(() => {});
    },
    //添加家长
    handleAddParent() {
      this.parentDialogVisible = true;
    },
    //删除家长
    handleDel(row) {
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h(
            "span",
            {
              style:
                "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
            },
            "确定要删除该家长信息？"
          ),
          h(
            "div",
            {
              style:
                "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
            },
            "是否确定删除该家长信息，删除后，将无法恢复。"
          ),
        ]),
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          iconClass: "el-icon-question",
          type: "warning",
          customClass: "warningMessage",
        }
      )
        .then(() => {})
        .catch(() => {});
    },
    //弹窗关闭
    handleClose() {
      this.parentDialogVisible = false;
      this.$nextTick(() => {
        this.parentDialogForm = {
          relationship: "",
          parentName: "",
          parentPhone: "",
          cardType: "",
          cardNum: "",
          address: "",
        };
        this.buttonloading = false;
      });
    },
    //提交操作
    handleSubmit() {
      this.$refs.parentDialogForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = {
            relationship: this.parentDialogForm.relationship,
            parentName: this.parentDialogForm.parentName,
            parentPhone: this.parentDialogForm.parentPhone,
            cardType: this.parentDialogForm.cardType,
            cardNum: this.parentDialogForm.cardNum,
            address: this.parentDialogForm.address,
          };
          let url = "";
          if (this.handleType == "add") {
            url = "/api/educourse/save";
          } else {
            params.id = this.courseId;
            url = "/api/educourse";
          }
          // this.$api.courseHandle(url, params, this.handleType).then((res) => {
          //   if (res.data.code === 0) {
          //     this.$message({
          //       message: "保存成功",
          //       type: "success",
          //       duration: 2000,
          //       onClose: () => {
          //         this.buttonloading = false;
          //         if (this.handleType == "add") {
          //           this.courseSettingDialogVisible = true;
          //           this.$nextTick(() => {
          //             this.courseId = res.data.data;
          //             this.courseSettingType = "introduce";
          //           });
          //         } else {
          //           this.pageLoading = true;
          //           this.getData();
          //         }
          //         this.handleClose();
          //       },
          //     });
          //   } else {
          //     this.buttonloading = false;
          //     this.$message.error(res.data.msg);
          //   }
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置
    resetStudent() {},
    //提交
    submitStudent() {},
  },
};
</script>
<style scoped lang="scss">
.student-manage-edit {
  padding: 30px 25px 20px 25px;
  background-color: #ffffff;
  position: relative;
  .student-manage-edit-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.3);
    .text {
      width: 100%;
      height: 100%;
      margin-left: 24px;
      font-size: 20px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      display: flex;
      align-items: center;
      .line {
        width: 24px;
        height: 3px;
        background-color: #597ef7;
        position: absolute;
        bottom: 0;
        left: 40px;
        transform: translateX(-50%);
      }
    }
  }
  .table-content {
    width: 100%;
    height: 400px;
  }
}
</style>